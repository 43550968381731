<template>
<v-app>
    <v-dialog v-model="showDialog" width="600">
        <v-card>
            <v-card-title class="text-h5">Editar Categoría</v-card-title>
            <v-card-text>
            <v-row>
                <v-col cols="12" sm="12" md="12">
                <v-text-field
                    v-model="category_name"
                    label="Categoría"
                    :rules="[v => !!v || 'Ingresar un nombre es requerido!']"
                ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                <v-select
                    v-model="parent_category"
                    :items="categoriesList"
                    item-text="name"
                    item-value="id"
                    label="Categoría Superior"
                ></v-select>
                </v-col>
            </v-row>

            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="success"
                    text
                    @click="sendForm"
                    >
                    Guardar
                </v-btn>
               <v-btn
                    color="primary"
                    text
                    @click="showDialog = false"
                    >
                    Cerrar
                </v-btn>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            category_id: undefined,
            category_name: undefined,
            parent_category : undefined,
            categories: [],
            categoriesList: [],
        }
    },
    created(){
        
        this.$parent.$on('openDialogCategoryEdit', (data) => {
            this.category_id = data.category_id;
            this.category_name = data.category_name;
            this.parent_category = data.parent_category;
            this.categories = data.categories;
            this.showDialog = true; 
            this.categoriesList = [];
            this.categoriesList.push(
                    {
                        id: 0,
                        name: '-',
                    }
                );
            this.categories.forEach(element => {
                if (element.id != this.category_id) {
                    this.categoriesList.push(element);
                }
            });
           
        });
    },
    methods: {
        sendForm(){
            var vm = this;
            if (vm.category_id == undefined) {
                
                this.axios({
                     url: "inventory/categories",
                    method: "POST",
                    data: {
                        name: vm.category_name,
                        parent_category:vm.parent_category,
                    },
                })
                .then((response) => {
                    vm.showDialog = false;
                    vm.$emit('success', "Categoría creada correctamente");
                })
                .catch((error) => {
                console.log(error);
                });

            }
            else{
                this.axios({
                    url: "inventory/categories/" + vm.category_id,
                    method: "PUT",
                    data: {
                        name: vm.category_name,
                        parent_category:vm.parent_category,
                    },
                })
                .then((response) => {
                    vm.showDialog = false;
                    vm.$emit('success', "Categoría modificado correctamente");
                })
                .catch((error) => {
                console.log(error);
                });
            }
            
        },
    }
}
</script>